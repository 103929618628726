import React, { useEffect, useState } from "react";
import { Container, Button, Form, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./login.css";
//Custom Component
import { setUserSession } from "../Utils/Common/Common";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [loggedIn] = useState(sessionStorage.getItem("userId"));
  // const previousRoute = history.goBack();
  // useEffect(() => {
  //   if (loggedIn) {
  //     history.push("/addCountry");
  //   }
  // }, []);

  const history = useHistory();

  const loginCredentials = {
    name: "Admin",
    pass: "firevpn@admin1234",
  };

  const loginAction = () => {
    if (
      username == loginCredentials.name &&
      password == loginCredentials.pass
    ) {
      setUserSession();
      sessionStorage.setItem("userId", username);
      history.push("/addCountry");
    } else {
      setError(true);
    }
  };
  return (
      <Container className="content">
        <Row>
          <Col>
            <div className="login-form">
              <div style={{ textAlign: "center", marginBottom: 20 }}>
                <h1>Login</h1>
              </div>
              <div className="form">
                {error && (
                  <h5 style={{ color: "red", textAlign: "center" }}>
                    Wrong Credentials
                  </h5>
                )}
                <Form.Group className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Button
                  className="button"
                  onClick={() => loginAction()}
                  variant="primary"
                >
                  Submit
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
  );
}

export default Login;
