import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
//Custom Components Import
import ProtectedRoutes from "./Utils/ProtectedRoutes/ProtectedRoutes";
//Pages Import
import AddServers from "./Pages/AddServers";
import AddCountry from "./Pages/AddCountry";
import ShowCountries from "./Pages/ShowCountries";
import ShowServers from "./Pages/ShowServers";
import EditServers from "./Pages/EditServers";
import Login from "./Pages/Login";
import EditCountry from "./Pages/EditCountry";
import AddFaq from "./Pages/AddFaq";
import AllFaq from "./Pages/AllFaq";
import EditFaq from "./Pages/EditFaq";
import AddBlogs from "./Pages/AddBlogs";
import EditBlogs from "./Pages/EditBlogs";
import AllBlogs from "./Pages/AllBlogs";
import UserSuggestions from "./Pages/UserSuggestions";
import { QueryClientProvider, QueryClient } from "react-query";
import UploadImg from "./Pages/UploadImg";
import UserEnquiry from "./Pages/UserEnquiry";
import IapPlans from "./Pages/Iapplans";


const queryClient = new QueryClient();
function App() {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Login} exact />
            <ProtectedRoutes path="/addCountry" component={AddCountry} />
            <ProtectedRoutes path="/addServer" component={AddServers} />
            <ProtectedRoutes path="/showCountries" component={ShowCountries} />
            <ProtectedRoutes path="/showServers" component={ShowServers} />
            <ProtectedRoutes path="/editServers" component={EditServers} />
            <ProtectedRoutes path="/editCountry" component={EditCountry} />
            <ProtectedRoutes path="/addFaq" component={AddFaq} />
            <ProtectedRoutes path="/allFaq" component={AllFaq} />
            <ProtectedRoutes path="/editFaq" component={EditFaq} />
            <ProtectedRoutes path="/addBlogs" component={AddBlogs} />
            <ProtectedRoutes path="/editBlogs" component={EditBlogs} />
            <ProtectedRoutes path="/allBlogs" component={AllBlogs} />
            <ProtectedRoutes path="/getLink" component={UploadImg} />
            <ProtectedRoutes path="/userSuggestions" component={UserSuggestions} />
            <ProtectedRoutes path="/userEnquirey" component={UserEnquiry} />
            <ProtectedRoutes path="/iapplans" component={IapPlans} />


            {/* <Route path="/addCountry" component={AddCountry} /> */}
          </Switch>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
