import React, { forwardRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { ToastContainer, toast } from "react-toastify";
import {
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import { Container, Button, Card } from "react-bootstrap";
import axios from "../Components/Axios/Axios";
//Custom Components Import
import Loader from "../Components/Spinner/Spinner";
import Sidebar from "../Components/Sidebar/MainSidebar/Sidebar";
import requests from "../Components/Requests/Requests";

function AllFaq() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    getAllFaq();
  }, []);

  const getAllFaq = async () => {
    setLoading(true);
    try {
      const responce = await axios.get(requests.allFaq);
      // console.log(responce);
      if (responce.data.Success === 1) {
        setData(responce.data.data);
        setLoading(false);
      } else {
        toast.warning(`Something went wrong`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      toast.warning(err, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <>
      <Sidebar />
      <Container className="content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Card className="card">
          <Card.Header className="heading">
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="inside-header"
            >
              All FAQ
              <Link to="/addFaq">
                <Button className="table-button" variant="primary" size="md">
                  Add FAQ
                </Button>
              </Link>
            </div>
          </Card.Header>
          <Card.Body className="body">
            {loading == true ? (
              <Loader margin={"auto"} />
            ) : (
              <MaterialTable
                data={data}
                icons={tableIcons}
                columns={[
                  {
                    title: "#",
                    field: "tableData.id",
                    render: (rowData) => rowData.tableData.id + 1,
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                      borderLeft: "1px solid #e0e0e0",
                      wordBreak: "break-all",
                    },
                  },
                  {
                    title: "Question",
                    field: "que",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                      wordBreak: "break-all",
                    },
                  },
                  {
                    title: "Answer",
                    field: "ans",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                      wordBreak: "break-all",
                    },
                  },
                  {
                    title: "Type",
                    field: "type",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                      wordBreak: "break-all",
                    },
                  },
                  {
                    title: "Active/Inactive",
                    field: "status",
                    lookup: {
                      1: <Button variant="success">Active</Button>,
                      0: <Button variant="danger">Inactive</Button>,
                    },
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                  },
                  {
                    title: "Edit",
                    render: (rowData) => (
                      <Link to={{ pathname: "/editFaq", state: rowData }}>
                        <Button variant="primary" size="md">
                          Edit
                        </Button>
                      </Link>
                    ),
                  },
                ]}
                options={{
                  pageSize: 15,
                  pageSizeOptions: [
                    25, 40,
                    // { value: data.length, label: data.length },
                  ],
                  exportButton: true,
                  showTitle: false,
                  // pageSize: 10,
                }}
              />
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default AllFaq;
