import axios from "../Components/Axios/Axios";
import React, { useEffect, useState } from "react";
import { Container, Button, Card, Form, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../Components/Sidebar/MainSidebar/Sidebar";
import Loader from "../Components/Spinner/Spinner";
import requests from "../Components/Requests/Requests";

function IAPProces() {
  const [mac_yearly, setmacyearly] = useState("");
  const [mac_monthly, setmacmonthly] = useState("");
  const [mac_weekly, setmacweekly] = useState("");
  const [and_monthly, setandmonthly] = useState("");
  const [and_weekly, setandweekly] = useState("");
  const [and_yearly, setandyearly] = useState("");
  const [ios_monthly, setiosmonthly] = useState("");
  const [ios_weekly, setiosweekly] = useState("");
  const [ios_yearly, setiosyearly] = useState("");

//   const [showError, SetShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // countryList();
  }, []);

  const add = async () => {
    setLoading(true);
    const body = {
      mac_yearly: mac_yearly,
      mac_weekly: mac_weekly,
      mac_monthly: mac_monthly,
      ios_weekly: ios_weekly,
      ios_monthly: ios_monthly,
      ios_yearly: ios_yearly,
      and_weekly: and_weekly,
      and_monthly: and_monthly,
      and_yearly: and_yearly,
    };
    // console.log("body.........",body);
    if (and_weekly!=='' ||and_yearly!==''||and_monthly!=='') {
      try {
        const responce = await axios.post(requests.IapPlans, body);
        // console.log("server added....",responce);
        if (responce.data.Success === 1) {
          setLoading(false);
          toast.success("Plan Updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2500);
        } else {
          toast.warning(`addserver Something went wrong`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        toast.warning(err, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          setLoading,
        });
      }
    } else {
      setLoading(false);
      toast.warning(`All fields are Required`, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  
  const UpdatePlans = () => {
    // let linkValidation = filePath.substring(0, 4);
    if (and_weekly!=='' ||and_yearly!==''||and_monthly!=='') {
    //   if (linkValidation == "http") {
        add();
    //   } else {
    //     SetShowError(true);
    //   }
    } else {
      // console.log(
      //   filePath.length,
      //   fileName.length,
      //   countryName.length,
      //   status.length
      // );
      toast.warning("All the Fields are Required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // console.log(serverName, serverLink, serverLocation, countryName);
  };
  return (
    <>
      <Sidebar />
      <Container className="content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Card>
          <Card.Header className="heading">IAP Plans</Card.Header>
          <Card.Body>
            <Row>
              <Col className=" col-sm-4 main">
                <Form.Group className="mb-3">
                  <Form.Label>
                    Android Yearly <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="$4.99"
                    onChange={(e) => setandyearly(e.target.value)}
                  />
                  
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Android Monthly <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="$2.99"
                    onChange={(e) => setandmonthly(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Android Weekly <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="$1.99"
                    onChange={(e) => setandweekly(e.target.value)}
                  />
                </Form.Group>

                {/* <Form.Group className="mb-3">
                  <Form.Label>Server Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Server Location"
                    onChange={(e) => SetServerLocation(e.target.value)}
                  />
                </Form.Group> */}


              </Col>
              <Col className=" col-sm-4 main">
                <Form.Group className="mb-3">
                  <Form.Label>
                    iOS Yearly <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="$4.99"
                    onChange={(e) => setiosyearly(e.target.value)}
                  />
                  
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    iOS Monthly <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="$2.99"
                    onChange={(e) => setiosmonthly(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    iOS Weekly <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="$1.99"
                    onChange={(e) => setiosweekly(e.target.value)}
                  />
                </Form.Group>

                {/* <Form.Group className="mb-3">
                  <Form.Label>Server Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Server Location"
                    onChange={(e) => SetServerLocation(e.target.value)}
                  />
                </Form.Group> */}


              </Col>
              <Col className=" col-sm-4 main">
                <Form.Group className="mb-3">
                  <Form.Label>
                    MacOS Yearly <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="$4.99"
                    onChange={(e) => setmacyearly(e.target.value)}
                  />
                  
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    MacOS Monthly <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="$2.99"
                    onChange={(e) => setmacmonthly(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    MacOS Weekly <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="$1.99"
                    onChange={(e) => setmacweekly(e.target.value)}
                  />
                </Form.Group>

                {/* <Form.Group className="mb-3">
                  <Form.Label>Server Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Server Location"
                    onChange={(e) => SetServerLocation(e.target.value)}
                  />
                </Form.Group> */}


              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="footer">
            {loading === true ? (
              <Loader margin={0} />
            ) : (
              <Button
                className="submit-btn"
                onClick={() => UpdatePlans()}
                variant="primary"
              >
                Update
              </Button>
            )}
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}

export default IAPProces;
